import React, { Component } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
 

import outsourcersNew from "../Components/Outsourcers/outsourcersNew";
import outsourcersContainer from "../Containers/AgentContainer"; 
import outsourcersList from "../Components/Outsourcers/OutsourcersList/index";
function mapStateToProps(state) {
  return { auth: state.session.auth, session: state.session };
}

class SousTraitantsListContainer extends Component {
  render() {
    return (
      <Switch>

<Route exact path='/soustraitants' component={outsourcersList} />
        <Route exact path='/soustraitants/new' component={outsourcersNew} />
        <Route path='/soustraitants/:userid' component={outsourcersContainer} />

 

      </Switch>
    );
  }
}

export default connect(mapStateToProps)(SousTraitantsListContainer);
