import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import debounce from 'lodash/debounce';
import { startLoading, doneLoading } from '../../Redux/actions/index';
import * as helpers from '../../Utils/Helpers';
import api from '../../Utils/Api';
import { Trans } from "react-i18next";

const mapStateToProps = state => {
	return {
		user: state.session.user,
		filters: state.filters
	}
}

const mapDispatchToProps = dispatch => {
	return {
		isLoading: e => dispatch(startLoading(e)),
		doneLoading: () => dispatch(doneLoading())
	}
}

class AgentNew extends Component {


	constructor(props) {
		super(props);
	 

 
		this.formSubmit = this.formSubmit.bind(this); 
		 
	}

 

	
	formSubmit(e) {
		e.preventDefault();

		this.props.isLoading();

		let that = this;


		const data = helpers.formToObject(e.target);
		
		 
		api.post('/api/outsourcer/register', data)	
		.then(function(response) {
			console.log("that.props.history",that.props.history)
			if(response.data.success) {
				that.props.history.push('/soustraitants');
			} else {

				that.props.setFlash({
					active: true,
					message: response.data.error,
					classes: "warning",
					style: {}
				});

			}
			
			that.props.doneLoading();

		}).catch(erro => console.log(erro))


	}

	componentDidMount() {
		
	}

	render() {

		
		return(
			<div>

				<div className={"container-header"}>
					<Trans i18nKey="label_new_outsourcer" />
				</div>

				<form method="#" onSubmit={this.formSubmit}>

				<div className={"row"}>
				<div className={"col-md-5"}>
 				<h4 className={"mb-4"}><Trans i18nKey="outsourcer_name" /></h4>


				<div className={"form-group"}>
					<label>Label</label>
					<div class="input-group">
						<input type="text" className={"form-control"} name="label"  />
					</div>
				</div>


				<div className={"form-group"}>
					<label>Value</label>
					<div class="input-group">
						<input type="text" className={"form-control"} name="value"  />
					</div>
				</div>

				 

				




  				<div className={"mt-5"}>
				  <button
					 
				 	className={"btn btn-success float-right col-md-6"}>
  				<Trans i18nKey="button_create" />
  				</button>
  				</div>


  				</div>
  				


  				</div>

  				</form>
			</div>
		)
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(AgentNew)