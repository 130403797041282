import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";
import api from "../../Utils/Api"; 
import Restricted from "../../Utils/Restricted";

const UserEditOverlay = ({
  selected_user,
  toggleEditOverlay,
  open,
  role,
  ...props
}) => {
  const [permissionsOverlay, setPermissionsOverlay] = useState(false);
 
  const submitSiteForm = (e) => {
    props.isLoading({
      message: "Mise à jour en cours",
      animated: true,
    });

    e.preventDefault();

    const formdata = new FormData(e.target);

    let data = {};
    for (var [key, value] of formdata.entries()) data[key] = value;

    data["user"] = props.user;

    toggleEditOverlay();

    api.post("/api/outsourcers/edit/" + selected_user.id,data).then((res) => {
      props.reload();
      props.doneLoading();
    });
  };

  const managePermissions = (show) => {
    toggleEditOverlay(show);
    if (typeof show !== "undefined") setPermissionsOverlay(show);
    else {
      setPermissionsOverlay(true);
    }
  };

  return (
    <>
     

      <div className={"ui-overlay" + (open ? " open" : "")}>
        <div className={"ui-overlay-content anomalie-history col-md-6"}>
          <button
            onClick={toggleEditOverlay}
            className={"ui-overlay-close btn btn-danger btn-sm"}
          >
            <FontAwesomeIcon icon={faTimes} />
          </button>

          <h5>Modifier sous traitant</h5>

          <form onSubmit={submitSiteForm}>
            {open && (
              <>
                {/* <input type="hidden" name="userid" value={this.props.user.userid} /> */}

                
 

                  
                
                <div className="form-group custom-inline-form form-inline mt-3">
                  <label className={"font-exp"}>Label</label>
                  <input
                    type="text"
                     
                    name="label"
                    className={"form-control col-md-8"}
                    defaultValue={selected_user.label} 
                  />
                </div>

                <div className="form-group custom-inline-form form-inline mt-3">
                  <label className={"font-exp"}>Value</label>
                  <input
                    type="text"
                    name="value"
                    className={"form-control col-md-8"}
                  defaultValue={selected_user.value}  
                  />
                </div>

               
 
 
 

                <div className="form-group custom-inline-form form-inline mt-3 no-gutters">
                  <input
                    type="submit"
                    className={"btn btn-primary col-md-12"}
                    value="Valider les modifications"
                  />
                </div>
              </>
            )}
          </form>
        </div>
      </div>
    </>
  );
};

export default UserEditOverlay;
