//const apiURL = process.env.REACT_APP_BACKEND_URL ? process.env.REACT_APP_BACKEND_URL : 'http://localhost/netfersmartsolutions-witrack-rollout-backend-984317795bff/public';
// const apiURL = process.env.REACT_APP_BACKEND_URL ? process.env.REACT_APP_BACKEND_URL : 'https://api.wtrack-rollout.n-smartsolutions.com';

//SAM
//const apiURL = process.env.REACT_APP_REMOTE_URL ? process.env.REACT_APP_REMOTE_URL : 'https://api.rollout.witrack.digital';

const apiURL = process.env.REACT_APP_REMOTE_URL ? process.env.REACT_APP_REMOTE_URL : 'https://api.ssr.witrack.link';

//const apiURL =  "http://127.0.0.1:8000"
//cerine
// https://wtrack-rollout.n-smartsolutions.com

export default {

    apiURL,
    apiEndPoints: {
        CONNECT: '/api/user/connect',
        FETCH_NOTIFICATIONS: '/api/notification/fetch',
        FETCH_FILTERS: '/api/form/filters',
        FETCH_TASK_GLOBAL:'/api/taskGlobal/fetch',
        FETCH_TASK_DETAIL: id => '/api/task/fetch?detail=true&pm='+id,
        FETCH_SSR_TASK_DETAIL: id => '/api/ssr/task/fetch?detail=true&pm='+id,
        EXPORT_TASK: id => apiURL+"/api/pm/export/igeneral/"+id,
        EXPORT_TASK_ANOMALIES: id => apiURL+"/api/anomalies/checks_export/"+id,
        EXPORT_SSR_TASK: id => apiURL+"/api/ssr/export/ssr/"+id,
        EXPORT_SSR_TASK_ANOMALIES: id => apiURL+"/api/ssr/anomalies/checks_export/"+id,
        EXPORT_TASK_GPM: id => apiURL+"/api/gpm/export/igeneral/"+id,
        EXPORT_TASK_ANOMALIES_GPM: id => apiURL+"/api/gpm/anomalies/checks_export/"+id,
        EXPORTPDF_SSR_TASK: id => apiURL+"/api/ssr/exportxlsx/ssr/"+id,

        EXPORT_TMDB: "/api/tmdb/export",
    }
}
