import React, { Component } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import SitesListContainer from "../Containers/SSR/SitesContainer";
import AddressNewContainer from "../Containers/SSR/AddressNewContainer";
import PlanningList from "../Components/Planning/PlanningList";
import SiteDetail from "../Components/Addresses/SiteDetail";
import TaskListContainer from "../Containers/SSR/TaskListContainer";
import AnomaliesContainer from "../Containers/SSR/AnomaliesContainer";
import CalendarPage from "../Components/Planning/CalendarPage";
import DashboardWrapper from "../Components/Dashboard/Pages/DashboardWrapper";

function mapStateToProps(state) {
  return { auth: state.session.auth, session: state.session };
}

class SSRRouter extends Component {
  render() {
    console.log("ssr router",this.props.filters)
    return (
      <Switch>

            <Route exact path="/ssr/sites" component={SitesListContainer} />
            <Route exact path="/ssr/sites/new" component={AddressNewContainer} />
            <Route path="/ssr/sites/detail/:id" component={SiteDetail} />


            <Route exact path="/ssr" component={TaskListContainer} />
            <Route exact path="/ssr/task" component={TaskListContainer} />
            <Route exact path="/ssr/task/:taskid/" component={TaskListContainer} />
            <Route exact path="/ssr/task/agent/:agentid/" component={TaskListContainer} />
            <Route exact path="/ssr/task/site/:site/" component={TaskListContainer} />


            {/* <Route path='/ssr/anomalies' component={AnomaliesContainer} /> */}
            {/* <Route exact path='/ssr/planning' component={PlanningList} /> */}
            {/* <Route path='/ssr/calendar' component={CalendarPage} /> */}
            {/*<Route path='/ssr/dashboard' component={DashboardWrapper} />*/}

      </Switch>
    );
  }
}

export default connect(mapStateToProps)(SSRRouter);
