import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MoreInfoDialog, { Item } from "../../../Utils/MoreInfoDialog";
import { faPen, faInfo, faPlay, faPowerOff, faTrash } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";

const moment = require("moment");

const AgentRow = ({ user, selectUser, enableUser, disableUser, userDeleteBtn, ...props }) => {


    const {filters} = useSelector(state => ({filters: state.filters}));
    const version = (user.device_info && user.device_info["app-version"]) ? user.device_info["app-version"][0] : 0;


    const upToDate = filters.app_version === version

    
  return (
    <div className={"row ctable-row"}>
      <div className={"col-md-3"}>
        <div className={"agent-list-profile"}>
          <div className={"col-profile-ct"}>
            {/*<span className={"agent-list-photo task-status status-id-2"}>{user.account_type}</span>*/}

            <span className={"agent-list-username"}>
              <span>{user.id}</span>
             
            </span>
          </div>
        </div>
      </div>

      <div className={"col-md-3"}>{user.label}</div>

      <div className={"col-md-2"}>{user.value} </div>

      <div className={"col-md-2 site-plan-col-date"}>
        <span>
          { 
              moment(user.created_at)
                .format("YYYY-MM-DD HH:mm")
                .toString()
             }
        </span>

        {user.device_info && user.device_info["app-version"] ? (
          <span className={"app-version-tag "+(upToDate ? "btn-success" : "btn-danger")}>{user.device_info["app-version"][0]}</span>
        ) : (
          ""
        )}
      </div>

      
      
      <div className={"col-md-1 task-details-col"}>
       {/*  <Link to={"/agents/" + user.agentid} className={"task-action-btn ml-2 "}>
          <FontAwesomeIcon icon={faInfo} /> Details
        </Link> */}
       
            <MoreInfoDialog>
              <Item onClick={() => selectUser(user)}>
                <FontAwesomeIcon className={"mr-2 "} icon={faPen} /> Modifier
              </Item>
          
              <Item onClick={() => userDeleteBtn(user.id)}>
                <FontAwesomeIcon className={"mr-2 "} icon={faTrash} /> Supprimer
              </Item>
            </MoreInfoDialog>
           
        
      </div>
    </div>
  );
};

export default AgentRow;
